.app {
  overflow-x: hidden;
}

.meganews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
}
.meganews .related-blogs:first-child {
  border-top: 0;
}
section.election-bg {
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.meganews .custom-card .clearfix a:first-child {
  border-top: 0;
}

.main-voting-poll {
  border: 0.5px solid #ae0a0b;
  width: 100%;
}
.heading-vote {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 5px;
}
.heading-vote2 {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: x-large;
}

th,
table {
  border-spacing: 3px;
  border-collapse: collapse;
}
th.district {
  border-collapse: unset;
}

th.district {
  color: white;
  width: 25%;
  padding-bottom: 0%;
}
.Voting-table2 th.district {
  width: 35%;
}
.Voting-table2 th.district span {
  background-color: #000;
  padding: 8px;
  display: inline;
  padding: 7px 20px 7px 11px;
  border-radius: 0px 5px 5px 0px;
}

th.district span {
  background-color: #000;
  padding: 4px;
  display: inline;
  border-radius: 0px 5px 5px 0px;
}

.Voting-table2 th.cong {
  background-color: red;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}

th.cong {
  color: white;
  width: 19%;
  font-size: 8px;
  padding: 4px;
  background-color: red;
  text-align: center;
}
.Voting-table2 th.aap {
  background-color: #009ada;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}
th.aap {
  background-color: #009ada;
  color: white;
  width: 19%;
  font-size: 8px;
  text-align: center;
  padding-bottom: 0%;
}
.Voting-table2 th.bjp {
  background-color: orange;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}

th.bjp {
  padding-bottom: 0%;
  color: white;
  width: 19%;
  text-align: center;
  background-color: orange;
  font-size: 8px;
}
.Voting-table2 th.other {
  background-color: grey;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}
td {
  text-align: center;
  padding: 6px;
  font-size: 12px;
  font-weight: 500;
}
th.other {
  background-color: grey;
  padding-bottom: 0%;
  color: white;
  text-align: center;
  width: 20%;
  font-size: 8px;
}
.col-xl-6.polltable {
  padding-left: 5px;
  padding-right: 7px;
  padding-bottom: 10px;
}

td.district {
  border-left: dashed 1px grey;
  border-right: dashed 1px grey;
}
td.cong {
  border-left: dashed 1px grey;
  border-right: dashed 1px grey;
}

tr:last-child {
  border-bottom: none;
}
tr {
  border-bottom: 1px solid black;
}
th.district-name {
  padding-left: 4px;
}

.Voting-table {
  width: 100%;
  font-size: 10px;
}
.Voting-table2 {
  font-size: 13px !important;

  width: 100%;
}
.votes {
  font-weight: 900;
}
.party-name {
  background: #ae0a0b;
  color: white;
  text-align: center;
  font-size: 18px;
  padding: 8px;
}

.party-name2 {
  background: #ae0a0b;
  color: white;
  text-align: center;
  display: flex;
  font-size: 15px;
  padding: 7px;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
}
.ImageSwastik {
  height: 49px;
  position: absolute;
  width: 39px;
  top: -7px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.heading-main {
  position: relative;
  padding-bottom: 10px;
}
.col-xl-12.polltable {
  padding-left: 10px;
  padding-right: 10px;
}

.donut-chart {
  padding-bottom: 10px;
}

.chart {
  padding: 8px;
}
.donutchart-compo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: center;
  align-items: flex-start;
}
element.style {
  background: rgb(255, 207, 4);
}
li.item-name {
  margin: 3px;
  font-size: 10px;
  font-weight: bold;
}
ul.items-names1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

ul.items-names {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

.counter {
  position: absolute;
  top: 30%;
  left: 50%;
  bottom: 0%;
  text-align: center;
  font-weight: 800;
  transform: translateX(-50%);
}
p.total {
  border-bottom: 1px solid grey;
  font-size: 11px;
}
p.outof {
  color: grey;
}
.donut-count {
  position: relative;
  font-size: 9px;
}

@media (max-width: 1200px) {
  .party-name2 {
    background: #ae0a0b;
    color: white;
    text-align: center;
    display: flex;
    font-size: 13px;
    padding: 5px;
    justify-content: space-around;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .ImageSwastik {
    height: 39px;
    position: absolute;
    width: 26px;
    top: -7px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.Raji-Ka-Rang {
  padding-top: 15px;
  padding-bottom: 15px;
}

.match-card.match2 {
  height: 401px;
  overflow: hidden;
}
.match-card {
  border-top: 10px solid #ae0a0b;
  border-bottom: 10px solid #ae0a0b;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 5px 15px 25px;
  position: relative;
  box-shadow: 4px 4px 20px 0 rgb(6 6 6 / 15%);
}

.marquee-link {
  transition: 0.3s;
}
.marquee-link:hover {
  color: #ae0a0b;
}

marquee {
  margin: 0 auto;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 50px;
  height: 30px;
  content: "";
  z-index: 1;
}
.marquee:before {
  left: 0;
}
.marquee:after {
  right: 0;
}
.marquee__content {
  width: 1200%;
  display: flex;
  line-height: 30px;
  animation: marquee 60s linear infinite forwards;
}
.marquee__content ul {
  width: 400%;
  font-size: 17px;
  font-weight: bold;
}
.marquee__content ul li span {
  margin: 0 15px;
}
.marquee__content:hover {
  animation-play-state: paused;
}
.list-inline {
  display: flex;
  justify-content: space-around;
  width: 33.33%;
  list-style: none;
  padding: 0;
  margin: 0;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-66.6%);
  }
}

.meganews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
}
.meganews .related-blogs:first-child {
  border-top: 0;
}
section.election-bg {
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.meganews .custom-card .clearfix a:first-child {
  border-top: 0;
}

.main-voting-poll {
  border: 0.5px solid #ae0a0b;
  width: 100%;
}
.heading-vote {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 5px;
}
.heading-vote2 {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: x-large;
}

th,
table {
  border-spacing: 3px;
  border-collapse: collapse;
}
th.district {
  border-collapse: unset;
}

th.district {
  color: white;
  width: 40%;

  padding-bottom: 0%;
}
.Voting-table2 th.district {
  width: 35%;
}
.Voting-table2 th.district span {
  background-color: #000;
  padding: 8px;
  display: inline;
  padding: 7px 20px 7px 11px;
  border-radius: 0px 5px 5px 0px;
}

th.district span {
  background-color: #000;
  padding: 4px;
  display: inline;
  border-radius: 0px 5px 5px 0px;
}

.Voting-table2 th.cong {
  background-color: red;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}

th.cong {
  color: white;
  width: 19%;
  font-size: 8px;
  padding: 4px;
  background-color: red;
  text-align: center;
}
.Voting-table2 th.aap {
  background-color: #009ada;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}
th.aap {
  background-color: #009ada;
  color: white;
  width: 19%;
  font-size: 8px;
  text-align: center;
  padding-bottom: 0%;
}
.Voting-table2 th.bjp {
  background-color: orange;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}

th.bjp {
  padding-bottom: 0%;
  color: white;
  width: 19%;
  text-align: center;
  background-color: orange;
  font-size: 8px;
}

.Voting-table2 th.other {
  background-color: grey;
  padding: 8px;
  text-align: center;
  font-size: 10px;
}
td {
  text-align: center;
  padding: 6px;
  height: 32px;
  font-size: 12px;
  font-weight: 500;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

th.other {
  background-color: grey;
  padding: 4px;
  color: white;
  text-align: center;
  width: 20%;
  font-size: 8px;
}
.col-xl-6.polltable {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 10px;
}

td.district {
  border-left: dashed 0.5px grey;
  border-right: dashed 0.5px grey;
}
td.cong {
  border-left: dashed 0.5px grey;
  border-right: dashed 0.5px grey;
}
td.bjp.addflag {
  animation: blinker 1s linear;
}

@keyframes blinker {
  from {
    background-color: #ae0a0b;
  }
  to {
    background-color: white;
  }
}

td.other.addflag {
  animation: backgroundfade 1s 1 !important;
}

@keyframes backgroundfade {
  0% {
    background: #ae0a0b;
  }
  100% {
    background: #fff;
  }
}

td.cong.addflag {
  animation: backgroundfade 1s 1 !important;
}

@keyframes backgroundfade {
  0% {
    background: #ae0a0b;
  }
  100% {
    background: #fff;
  }
}

tr:last-child {
  border-bottom: none;
}
tr {
  border-bottom: 1px solid black;
}

th.district-name {
  padding-left: 4px;
  animation: fadeIn 2s;
}

.Voting-table {
  width: 100%;
  font-size: 10px;
}
.Voting-table2 {
  font-size: 13px !important;

  width: 100%;
}
.votes {
  font-weight: 900;
}
.party-name {
  background: #ae0a0b;
  color: white;
  text-align: center;
  font-size: 18px;
  padding: 8px;
}

p.party-2 {
  margin-bottom: 0;
  font-size: 17px;
}

p.party-1 {
  margin-bottom: 0;
  font-size: 17px;
}
.party-name2 {
  background: #ae0a0b;
  color: white;
  text-align: center;
  display: flex;
  font-size: 15px;
  padding: 7px;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
}
.ImageSwastik {
  height: 49px;
  position: absolute;
  width: 39px;
  top: -7px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.heading-main {
  position: relative;
  padding-bottom: 10px;
}
.col-xl-12.polltable {
  padding-left: 10px;
  padding-right: 10px;
}

.donut-chart {
  padding-bottom: 10px;
}

.chart {
  padding: 8px;
}
.donutchart-compo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: flex-start;
}
element.style {
  background: rgb(255, 207, 4);
}

li {
  list-style: none;
}

li.item-name {
  margin: 3px;
  font-size: 10px;
  font-weight: bold;
}
ul.items-names1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

ul.items-names {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

.counter {
  position: absolute;
  top: 30%;
  left: 50%;
  bottom: 0%;
  text-align: center;
  font-weight: 800;
  transform: translateX(-50%);
}
p.total {
  border-bottom: 1px solid grey;
  font-size: 11px;
  margin-bottom: 0;
}
p.outof {
  color: grey;
}
.donut-count {
  position: relative;
  font-size: 9px;
}

@media (max-width: 1200px) {
  .party-name2 {
    background: #ae0a0b;
    color: white;
    text-align: center;
    display: flex;
    font-size: 13px;
    padding: 5px;
    justify-content: space-around;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .ImageSwastik {
    height: 39px;
    position: absolute;
    width: 26px;
    top: -2px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.Raji-Ka-Rang {
  padding-top: 15px;
  padding-bottom: 15px;
}

.match-card.match2 {
  height: 401px;
  overflow: hidden;
}
.match-card {
  border-top: 10px solid #ae0a0b;
  border-bottom: 10px solid #ae0a0b;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 5px 15px 25px;
  position: relative;
  box-shadow: 4px 4px 20px 0 rgb(6 6 6 / 15%);
}

td.district.addflag {
  animation: backgroundfade 1s 1 !important;
}

@keyframes backgroundfade {
  0% {
    background: #ae0a0b;
  }
  100% {
    background: #fff;
  }
}

.example1 {
  height: 50px;
  overflow: hidden;
  position: relative;
}
.example1 a {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: example1 15s linear infinite;
  -webkit-animation: example1 15s linear infinite;
  animation: example1 15s linear infinite;
}

@-moz-keyframes example1 {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes example1 {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes example1 {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.nameofparty1 {
  width: 100%;
  margin-right: 20px;
}
.nameofparty2 {
  width: 100%;
  margin-left: 20px;
}
svg {
  width: 70px;
}

@media (max-width: 1392px) {
  .chart {
    padding: 0px;
  }
}
@media (max-width: 1281px) {
  svg {
    width: 50px;
  }
  td {
    font-size: 10px;
    padding: 1px;
    max-width: 20px !important;
  }
  th,
  th.bjp,
  th.cong,
  th.aap,
  th.other {
    font-size: 7px;
    padding: 1px;
  }
  p.party-1,
  p.party-2 {
    font-size: 13px;
  }
  .ImageSwastik {
    top: -5px;
  }
}
@media (max-width: 1175px) {
  .heading-vote {
    font-size: 12px;
  }
  .th.district-name {
    padding-left: 2px;
  }
}
@media (max-width: 1080px) {
  li.item-name {
    font-size: 8px;
  }
}
@media (max-width: 1010px) {
  p.party-1,
  p.party-2 {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .chart {
    padding: 8px;
  }
  svg {
    width: 70px;
  }
  td {
    font-size: 12px;
    padding: 6px;
  }
  th,
  th.bjp,
  th.cong,
  th.aap,
  th.other {
    font-size: 8px;
    padding: 4px;
  }
  p.party-1,
  p.party-2 {
    font-size: 17px;
  }
  .ImageSwastik {
    top: -2px;
  }
  .heading-vote {
    font-size: 15px;
  }
  .th.district-name {
    padding-left: 4px;
  }
  li.item-name {
    font-size: 10px;
  }
}

.hr-main-div {
  position: relative;
}
.hr-main-div span {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  font-weight: 700;
  font-size: 17px;
}

.hr-after-donut {
  margin-top: 0px;
  border-color: rgb(174 10 11 / 50%);
}
